import { createApp } from 'vue'
import App from './App.vue'
import { VueShowdown } from 'vue-showdown'

createApp(App).mount('#app')

var express = require( 'express' );
var app = express();

app.use( express.static( 'public' ) );
app.listen( 8080 );
app.component('VueShowdown', VueShowdown);



