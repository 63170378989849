<template>
<div>
  <title>Bit By Byte Creations</title>
  <meta charset="utf-8">
  <meta name="viewport" content="width=device-width, initial-scale=1">
  <link rel="icon" href="img/ico/favicon.ico" type="image/ico">
  <link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/bootstrap/3.3.7/css/bootstrap.min.css">
  <link rel="stylesheet" href="https://fonts.googleapis.com/css?family=Roboto|Roboto+Condensed">
  <link rel="stylesheet" href="style.css">

      <nav class="navbar navbar-inverse navbar-fixed-top navbar-custom">
        <div class="container">
            <div class="navbar-header">
                <button type="button" class="navbar-toggle collapsed" data-toggle="collapse" data-target="#navbar" aria-expanded="false" aria-controls="navbar">
                    <span class="sr-only">Toggle navigation</span>
                    <span class="icon-bar"></span>
                    <span class="icon-bar"></span>
                    <span class="icon-bar"></span>
                </button>
                <a class="navbar-brand" href="#">
                    <img class="fill-height" src="./assets/img/BitByByte-Logo.png">
                </a>
            </div>
            <div id="navbar" class="navbar-collapse collapse">
                <ul class="nav navbar-nav">
                    <li id="home-link" class="active"><a href="#home">Home</a></li>
                    <li id="services-link"><a href="#services">Services</a></li>
                    <li id="career-link"><a href="#career">Career</a></li>
                    <li id="affiliates-link"><a href="#affiliates">Affiliates</a></li>
                    <li id="contact-link"><a href="#contact">Contact Us</a></li>
                </ul>
                <div class="nav navbar-nav navbar-right">
                    <div class="navbar-brand">EMBRACE THE MADNESS</div>
                </div>
            </div>
        </div>
    </nav>

    <div class="container back">
    <div class="container trans chap-violet">
        <div id="home" class="row">
            <div class="col-md-12"><br/></div>
            <div class="col-md-6 center pad-top">
             <h1 align="center" style="margin: 1.5em 1em 1.5em 1em;">Clean software development</h1>
            </div>
            <div class="col-md-6 center">
                <div class="fill-width"><img src="./assets/img/splash.svg"></div>
            </div>
            <div class="col-md-1"></div>
            <div class="col-md-2 center"><a href="#services" class="button-violet">SERVICES</a><img src="./assets/img/button-swag.svg" class="button-swag"></div>
            <div class="col-md-2 center"><a href="#career" class="button-violet">CAREER</a><img src="./assets/img/button-swag.svg" class="button-swag"></div>
            <div class="col-md-2 center"><a href="#affiliates" class="button-violet">AFFILIATES</a><img src="./assets/img/button-swag.svg" class="button-swag"></div>
            <div class="col-md-2 center"><a href="#contact" class="button-violet">CONTACT</a><img src="./assets/img/button-swag.svg" class="button-swag"></div>
        </div>
    </div>
    <div class="topslant"></div>
    <div class="container dark chap-blue">
        <div id="services" class="row">
            <div class="col-md-6">
                <div class="button-blue">OUR SERVICES</div>
                <div class="fill-width"><img src="./assets/img/services.svg"></div>
            </div>
            <div class="col-md-6">
                <div class="corners">
                        <h2>Custom software solutions</h2>
                        <p class="pad">
                          <ul>
                            <li> Full project work</li>
                            <li> Native mobile apps</li>
                            <li> Web Design</li>
                            <li> UX Design</li>
                            <li> Software Architecture</li>
                            <li> Consultation</li>
                          </ul>
                        </p>
                        <h2>Our Expertise</h2>
                        <p class="pad">
                          <ul>
                            <li>C# // Unity // Java</li>
                            <li>Java EE</li>
                            <li>Kotlin</li>
                            <li>Swift JavaScript</li>
                            <li>Typescript</li>
                            <li>React</li>
                            <li>Angular</li>
                            <li>Ext JS</li>
                            <li>PHP</li>
                            <li>Java-Server Faces</li>
                            <li>Vaadin</li>
                            <li>Visual Basic</li>
                            <li>Python</li>
                            <li>SQL</li>
                            <li>Bash script</li>
                            <li>HTML5</li>
                            <li>CSS</li>
                            <li>C++</li>
                            <li>Delphi</li>
                            <li>Firemonkey</li>
                            <li>GIT</li>
                            <li>SPSS scripting</li>
                            <li>ERP (MS Team Foundation, Atlassian Jira/Confluence)</li>
                        </ul>
                    </p>
                </div>
            </div>
        </div>
    </div>
    <div class="bottomslant"></div>
  <div class="topslant"></div>
  <div class="container dark chap-green">
      <div id="career" class="row">
          <div class="col-md-5">
              <div class="button-green">CAREER</div>
              <div id="vapp">
                    <p v-for="item in postings" :key="item">
                        <button class="button-green-small" v-on:click="jobPost(item)" @click="modalShow = !modalShow">
                    {{ item }}
                        </button>
                    </p>
                    <b-modal v-model="modalShow">
                        <div v-html="postContent"></div>
                    </b-modal>
                </div>
          </div>

          <div class="col-md-5">
              <div class="corners">
                  <center>
                      <img class="fill-width" src="./assets/img/splash.svg">
                      <h4>A snappy slogan to lure applicants.</h4>
                      <p class="project max-width">
                          "Send us an your application to bbb@bbb.com"
                      </p >
                  </center>
              </div>
          </div>
      </div>
  </div>

    <div class="container trans chap-gray">
        <div id="affiliates" class="row">
            <div class="col-md-12 title-padding">
                <div class="center"><div class="button-gray">AFFILIATES</div></div>
            </div>
            <div class="col-md-3 center">
                <a href="https://mitale.fi">
                    <img src="./assets/img/MiTale-color-Logo.png" class="logo">
                </a>
            </div>
            <div class="col-md-3 center">
                <a href="https://www.indium.fi/">
                    <img src="./assets/img/Indium-Logo.png" class="logo">
                </a>
            </div>
            <div class="col-md-3 center">
                <a href="https://www.snowfall.fi/">
                    <img src="./assets/img/Snowfall_black.png" class="logo">
                </a>
            </div>
            <div class="col-md-3 center">
                <a href="https://www.hesburger.fi/">
                    <img src="./assets/img/Hesburger-Logo.png" class="logo">
                </a>
            </div>
        </div>
    </div>

    <div class="topslant"></div>
    <div class="container dark chap-blue">
        <div id="contact" class="row">
            <div class="col-md-7">
                <div class="button-orange">CONTACT US</div>
                <div class="fill-width"><img src="./assets/img/contact.svg"></div>
            </div>
            <div class="col-md-5 center">
                <div class="corners">
                    <div style="margin: 7em 2em 7em 2em; padding: 2em;">
                        <center>
                            <h2>Interested in working with us?</h2>
                            <h4>We are equally excited for developing new or
                                building upon existing software solutions, let’s be in touch!
                                hello@bitbybyte.fi</h4>
                            <img src="./assets/img/temp.png"/>
                            <h3>Joni Salmela, CEO</h3>
                            <h4>joni.salmela@bitbybyte.fi</h4>
                            <img src="./assets/img/temp.png"/>
                            <h3>Mikko Lainio, COO</h3>
                            <h4>mikko.lainio@bitbybyte.fi</h4>
                        </center>
                    </div>
                </div>
            </div>
        </div>
    </div>
    </div>

  <footer class="navbar-inverse navbar-custom">
    <div class="container">
        <div class="nav navbar-nav navbar-right">
            <br/>
            <div class="text-muted align-text-end">Bit By Byte Creations</div>
            <div class="text-muted align-text-end">Turku Game Hub - TURUN TEKNOLOGIAKIINTEISTÖT, BioCity,</div>
            <div class="text-muted align-text-end">Tykistönkatu 6, 20520 Turku</div>
            <br/>
        </div>
    </div>
</footer>
</div>
</template>

<script>
import showdown from "showdown";

export default {
    name: 'App',
    data() {
        return{
            postings: [],
            postContent: null
        }
    },
    mounted() {
        const req = require.context('raw-loader!./assets/md/', false, /\.md$/i)
        for (const key of req.keys()) {
            console.log('key', {key});
            if(key != null){
                let name = key.toString().substring(
                    key.toString().lastIndexOf("/") + 1,
                    key.toString().lastIndexOf(".")
                );
                console.log(name);
                this.postings.push( name );
            }
        }
    },
    methods: {
        jobPost( title ) {
            var converter = new showdown.Converter();
            const data = require('raw-loader!./assets/md/' + title + '.md')
            var htmlData = converter.makeHtml(data.default);
            this.postContent = htmlData;
        }
    }

}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}

:root {
    --header: #393439;
    --header-text: #d2d2d2;
    --header-text2: #9b9b9b;
    --button1: #965bc2;
    --button1-text: #e4e3c1;
    --button2: #ba3f5f;
    --button2-text: #f9d6f0;
    --button3: #256fac;
    --button3-text: #ffffff;
    --button4: #f47125;
    --button4-text: #ffefcf;
    --button5: #6a6a6a;
    --button5-text: #e6e6e6;
    --button6: #3e9a92;
    --button6-text: #e4e4c2;
    --dark-gray: #e5e5e5;
    --light-gray: #f3f3f3;
}

body {
  background-color: var(--light-gray);
  font-family: Roboto;
}

h1, h2, h3 {
    font-weight: bold;
}

/* navbar */
.navbar-custom {
    color: var(--header-text2);
    background-color: var(--header);
    border-color: black;
    font-family: Roboto Condensed;
}

.navbar-custom .navbar-text {
    color: var(--header-text2);
}

.navbar-custom .navbar-brand {
    color:  var(--header-text2);
}

.navbar-custom .navbar-toggle {
    border-color: black;
}

.navbar-custom .navbar-toggle .icon-bar {
    background-color: var(--header-text2);
}

/* link */
.link-green {
    background-color: var(--button6);
    border: none;
    color: var(--button6-text);
    padding: 0.5em 1.5em;
    text-align: center;
    text-decoration: none !important;
    display: inline-block;
    font-size: 1.2em;
    margin: 0.5em;
    cursor: pointer;
    font-family: Roboto Condensed;
    letter-spacing: 2px;
}

/* buttons */
.button-violet {
    background-color: var(--button1);
    border: none;
    color: var(--button1-text);
    padding: 0.5em;
    text-align: center;
    text-decoration: none !important;
    display: inline-block;
    font-size: 1.2em;
    margin: 0.5em;
    cursor: pointer;
    font-family: Roboto Condensed;
    letter-spacing: 2px;
    min-width: 8em;
}

.button-red {
    background-color: var(--button2);
    border: none;
    color: var(--button2-text);
    padding: 0.5em 1.5em;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 1.8em;
    font-family: Roboto Condensed;
    letter-spacing: 2px;
    margin-left: -1.0em;
    width: 100%;}

.button-blue {
    background-color: var(--button3);
    border: none;
    color: var(--button3-text);
    padding: 0.5em 1.5em;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 1.8em;
    font-family: Roboto Condensed;
    letter-spacing: 2px;
    margin-left: -1.0em;
    width: 100%;}

.button-orange {
    background-color: var(--button4);
    border: none;
    color: var(--button4-text);
    padding: 0.5em 1.5em;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 1.8em;
    font-family: Roboto Condensed;
    letter-spacing: 2px;
    margin-left: -1.0em;
    width: 100%;
}

.button-gray {
    background-color: var(--button5);
    border: none;
    color: var(--button5-text);
    padding: 0.5em 1.5em;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 1.8em;
    font-family: Roboto Condensed;
    letter-spacing: 2px;
    margin-left: -1.0em;
    width: 100%;
}

.button-green {
    background-color: var(--button6);
    border: none;
    color: var(--button6-text);
    padding: 0.5em 1.5em;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 1.8em;
    font-family: Roboto Condensed;
    letter-spacing: 2px;
    margin-left: -1.0em;
    width: 100%;}

.button-green-small {
    background-color: var(--button6);
    border: none;
    color: var(--button6-text);
    padding: 0.2em 1.0em;
    text-align: left;
    text-decoration: none;
    display: inline-block;
    font-size: 1.0em;
    margin: 0.5em;
    font-family: Roboto Condensed;
    letter-spacing: 2px;
}

.button-swag {
    position: relative;
    top: 1.15em;
    right: 1.75em;
    float: right;
    height: 1.5em;
    width: 1.5em;
}

/* chapters */
.back {
    background-color: var(--light-gray);
    padding: 2em 0 0 0;
}

@media only screen and (min-width : 721px) {
    .dark {
        padding: 2em 10em 2em 10em;
        margin: 0em 0em 0em 0em;
        background-color: var(--dark-gray);
    }

    .trans {
        padding: 2em 10em 2em 10em;
    }
}

@media only screen and (max-width : 720px) {
    .dark {
        margin: 0em 0em 0em 0em;
        background-color: var(--dark-gray);
    }
}

.topslant {
	position: relative;
	top: 0.1em;
	height: 6em;
    margin: 0em 0em 0em 0em;
    background-color: var(--dark-gray);
    clip-path: polygon(100% 0%, 100% 100%, 0% 100%, 0 80%);
}

.bottomslant {
	position: relative;
	top: -0.1em;
	height: 6em;
    margin: 0em 0em 0em 0em;
    background-color: var(--dark-gray);
    clip-path: polygon(0% 0%, 100% 0%, 100% 20%, 0 100%);
}

.center {
    display: flex;
    align-items: center;
    justify-content: center;
}

.fill-height {
    height: 100%;
    /*height: -moz-available;           WebKit-based browsers will ignore this. */
    height: -webkit-fill-available;  /* Mozilla-based browsers will ignore this. */
    height: fill-available;
}

.fill-width {
    width: 100%;
    /*width: -moz-available;           WebKit-based browsers will ignore this. */
    width: -webkit-fill-available;  /* Mozilla-based browsers will ignore this. */
    width: fill-available;
}

.pad {
    padding: 1em 2em 1em 2em;
    font-size: 1.2em;
}

.pad-top {
    padding-top: 3em;
}


.align-text-end {
    text-align: end;
}

.chap-violet {
    color: var(--button1);
}

.chap-red {
    color: var(--button2);
}

.chap-blue {
    color: var(--button3);
}

.chap-orange {
    color: var(--button4);
}

.chap-gray {
    color: var(--button5);
}

.chap-green {
    color: var(--button6);
}

/* corners */
.corners {
    position: relative;
}

.cornered {
    padding: 0.9em
}

.corners .top-left-corner {
    position: absolute;
    top: 0; left: 0;
    width: 1.2em;
    height: 1.2em;
    overflow: hidden;
}

.corners .top-right-corner {
    position: absolute;
    top: 0; right: 0;
    width: 1.2em;
    height: 1.2em;
    overflow: hidden;
}

.corners .bottom-left-corner {
    position: absolute;
    bottom: 0; left: 0;
    width: 1.2em;
    height: 1.2em;
    overflow: hidden;
}

.corners .bottom-right-corner {
    position: absolute;
    bottom: 0; right: 0;
    width: 1.2em;
    height: 1.2em;
    overflow: hidden;
}

/**/
.project {
    min-height: 14em;
}

.info {
    min-height: 12em;
}

.logo-img {
    min-height: 10em;
}

.title-padding {
    padding-bottom: 2em;
}

.logo {
    width: 100%;
    /*width: -moz-available;           WebKit-based browsers will ignore this. */
    width: -webkit-fill-available;  /* Mozilla-based browsers will ignore this. */
    width: fill-available;
    max-width: 100%;
}

.max-width {
    max-width: 100%;
}

</style>
